exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-geschichte-tsx": () => import("./../../../src/pages/geschichte.tsx" /* webpackChunkName: "component---src-pages-geschichte-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-templates-blank-page-template-tsx": () => import("./../../../src/templates/BlankPageTemplate.tsx" /* webpackChunkName: "component---src-templates-blank-page-template-tsx" */),
  "component---src-templates-project-category-template-tsx": () => import("./../../../src/templates/ProjectCategoryTemplate.tsx" /* webpackChunkName: "component---src-templates-project-category-template-tsx" */),
  "component---src-templates-project-template-tsx": () => import("./../../../src/templates/ProjectTemplate.tsx" /* webpackChunkName: "component---src-templates-project-template-tsx" */),
  "component---src-templates-work-template-tsx": () => import("./../../../src/templates/WorkTemplate.tsx" /* webpackChunkName: "component---src-templates-work-template-tsx" */)
}

